<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('general.visaRespFiliere') }}</h2>
    </div>
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="12">
          <img :src="media.lien" width="50%">
        </el-col>
        <el-col :span="6">
          <el-row>
            <el-col :span="6">
              <el-button type="primary" :icon="editing ? 'el-icon-edit' : 'el-icon-plus'" circle @click="dialogUploadSignature=true" />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-button v-show="editing" type="primary" icon="el-icon-delete" circle @click="deleteSignature()" />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog
      :title="editing ? 'Modification '+$t('general.visaRespFiliere') : 'Ajout '+$t('general.visaRespFiliere')"
      :visible.sync="dialogUploadSignature"
      :before-close="handleCloseCreateDraw"
      :loading="true"
      width="30%"
    >
      <div class="content-inputs">
        <single-image
          :value="signatureUrl"
          @input="getImage($event)"
          @progress="signatureUploading=true"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="dialogUploadSignature=false">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="signatureUploading" @click="updateSignature()">
            {{ signatureUploading ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import SingleImage from '../../components/Upload/SingleImage.vue';
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
const visaResource = new Resource('visa-resp-filiere');
const mediaResource = new Resource('media');

export default {
  name: 'VisaRespFiliere',
  components: { SingleImage },
  directives: { waves, permission, role },
  data() {
    return {
      imageUrl: '',
      signatureUrl: '',
      dialogUploadSignature: false,
      signatureUploading: false,
      editing: true,
      media: {},
    };
  },
  created() {
    this.getVisaRespFiliere();
  },
  methods: {
    async getVisaRespFiliere(){
      const data = await visaResource.list();
      if (data.success) {
        this.editing = true;
        this.media = data.data;
      } else {
        this.editing = false;
        this.media.lien = data.message;
      }
    },
    getImage(img){
      this.signatureUrl = img;
    },
    async updateSignature(){
      this.signatureUploading = true;
      visaResource.store({ 'fichier': this.signatureUrl }).then(response => {
        if (response.success === false) {
          this.$message({
            type: 'error',
            message: response.message,
          });
          this.signatureUploading = false;
          this.dialogUploadSignature = false;
        } else {
          this.media = response.data;
          this.editing = true;
          this.signatureUploading = false;
          this.dialogUploadSignature = false;
        }
      }).catch(error => {
        this.$message({
          type: 'error',
          message: 'Erreurde sauvegarde !',
        });
        console.log(error);
      });
    },
    deleteSignature(){
      this.$confirm(this.$t('media.deleteSignature') + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        mediaResource.destroy(this.media.id).then(response => {
          this.$message({
            type: 'success',
            message: this.$t('media.suppressionEffectue'),
          });
          this.getVisaRespFiliere();
        }).catch(error => {
          console.log(error);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    handleCloseCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
